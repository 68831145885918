import * as React from 'react';
import { Fragment } from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PaidIcon from '@mui/icons-material/Paid';

export default function Online() {
  return (
    
    <Fragment>
          <List>
            <Link  to='/' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <HomeIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Home`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            
              <Link  to='/transfer' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <CurrencyExchangeIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`Transfer`} />
                    </ListItemButton>
                </ListItem>
              </Link>

              <Link  to='/transfers' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <PaidIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`History`} />
                    </ListItemButton>
                </ListItem>
              </Link>


              

              <Link  to='/transfer-pin' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <VpnKeyIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Transfer Pin`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/wallet' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`Deposit Crypto`} />
                    </ListItemButton>
                </ListItem>
              </Link>

              <Link  to='/withdrawals' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <CreditCardIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Crypto Withdrawals`} />
                  </ListItemButton>
              </ListItem>
            </Link>



              <Link  to='/kyc-verification' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <CoPresentIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Beneficiary`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            

            <Link  to='/cards' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <CreditCardIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Cards`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            

           

            <Link  to='/loans' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <CreditScoreIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Loans`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/referrals' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <Diversity3Icon />
                    </ListItemIcon>
                    <ListItemText  primary={`Referral`} />
                    </ListItemButton>
                </ListItem>
              </Link>

                     
          </List>
          <List>
            
            <Divider/>
            {/* <Link  to='https://tawk.to/chat/642872214247f20fefe94604/1gsuvrltc'  component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <HelpCenterIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Support`} />
                </ListItemButton>
            </ListItem>
          </Link> */}

          {/* <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <TelegramIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Telegram`} />
                </ListItemButton>
            </ListItem>
          </Link> */}

          {/* <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <EmailIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Email Contact`} />
                </ListItemButton>
            </ListItem>
          </Link> */}

          <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <SettingsIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Settings`} />
                </ListItemButton>
            </ListItem>
          </Link>
    </List>
      
    </Fragment>
  );
}